html, body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}
